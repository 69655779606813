<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="14" class="d-flex justify-content-center">
        <div id="print-label" class="label-shipment">
          <div class="label-shipment-image mb-3">
            <img src="@/assets/images/unboxing-warning.webp" width="100%" />
          </div>
          <div class="label-shipment-content">
            <a-row class="d-flex">
              <a-col :span="24" style="padding: 10px; text-align: left; font-size: 12px;">
                <div class="d-flex" style="font-weight: bold">
                  Pengirim
                </div>
                <div class="d-flex">
                  {{ sender?.name || shipment?.name || '' }}
                </div>
                <div v-if="sender?.address" class="d-flex">
                  {{ sender?.address }}
                </div>
                <div v-else-if="shipment?.address" class="d-flex">
                  {{ shipment.address?.line1 }}
                  {{ shipment.address?.line2 }},
                  {{ shipment.address?.sub_district }},
                  {{ shipment.address?.district }},
                  {{ shipment.address?.province }},
                  {{ shipment.address?.post_code }}
                </div>
                <div v-else class="d-flex">
                  -
                </div>
                <div class="d-flex">
                  {{ sender?.phone || shipment?.phone || '' }}
                </div>
              </a-col>
            </a-row>
            <a-row class="d-flex">
              <a-col :span="24" style="padding: 10px; text-align: left; font-size: 12px">
                <div class="d-flex" style="font-weight: bold">
                  Penerima
                </div>
                <div class="d-flex">
                  {{ destination?.name || '-' }}
                </div>
                <div class="d-flex">
                  <template v-if="destination.address">
                    {{ destination.address?.line1 }}
                    {{ destination.address?.line2 }},
                    {{ destination.address?.sub_district }},
                    {{ destination.address?.district }},
                    {{ destination.address?.province }},
                    {{ destination.address?.postal_code }}
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="d-flex">
                  {{ destination?.phone || '-' }}
                </div>
              </a-col>
            </a-row>
            <!-- <a-row
              class="d-flex justify-content-center"
              style="margin: 10px 0px"
            >
              <a-col :span="23" style="border: 1px solid #cccccc">
                <div style="font-size: 12px">
                  penjual <b>tidak perlu</b> bayar apapun ke kurir, sudah
                  dibayarkan otomatis
                </div>
              </a-col>
            </a-row>
            <a-row class="d-flex" style="padding-bottom: 40px">
              <a-col
                :span="12"
                style="margin: 0 10px; font-size: 12px; text-align: left"
              >
                <div class="d-flex">
                  kepada
                </div>
                <div class="d-flex" style="font-weight: bold">
                  {{ destination.name }}
                </div>
                <div class="d-flex">
                  {{ destination.address.line1 }}
                  {{ destination.address.line2 }},
                  {{ destination.address.sub_district }},
                  {{ destination.address.district }},
                  {{ destination.address.province }},
                  {{ destination.address.postal_code }}
                </div>
                <div class="d-flex">
                  {{ destination.phone }}
                </div>
              </a-col>
              <a-col
                :span="12"
                style="margin: 0 10px; font-size: 12px; text-align: left"
              >
                <div class="d-flex">
                  Dari:
                </div>
                <div class="d-flex" style="font-weight: bold">
                  {{ shipment.name }}
                </div>
                <div class="d-flex">
                  {{ shipment.addres }}
                </div>
                <div class="d-flex">
                  {{ shipment.phone }}
                </div>
              </a-col>
            </a-row> -->
            <div
              v-if="printFulfillmentId"
              style="
                margin-top: 10px;
                font-weight: 600;
                font-size: 14px;
                text-align: center;
                border-top: 1px dotted #cccccc;
              "
            >
              <div v-if="isExist" style="margin-top: 10px">
                <VueBarcode v-model="detail[0].fulfillment_number" :options="barcode" />
              </div>
              <div v-if="isExist" style="margin-bottom: 15px;">
                ID Pengiriman: {{ detail[0].fulfillment_number }}
              </div>
            </div>
            <div
              style="
                margin: 10px 0px;
                padding-top: 10px;
                font-size: 12px;
                text-align: left;
                border-top: 1px dotted #cccccc;
              "
            >
              <a-row class="d-flex">
                <a-col
                  :span="6"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    No Order
                  </div>
                </a-col>
                <a-col
                  :span="18"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    : {{ order.order_number }}
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex">
                <a-col
                  :span="6"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    Tanggal Order
                  </div>
                </a-col>
                <a-col
                  :span="18"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    : {{ parseISOString(order.order_date) }}
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex">
                <a-col
                  :span="6"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    Ongkos Kirim
                  </div>
                </a-col>
                <a-col
                  :span="18"
                  style="margin: 0 10px; font-weight: bold; text-align: left"
                >
                  <div class="d-flex">
                    : Rp {{ shipping.shipping_cost?.value }}
                  </div>
                </a-col>
              </a-row>
            </div>
            <div
              style="
                margin: 10px 0px;
                padding-top: 10px;
                font-size: 12px;
                text-align: left;
                border-top: 1px dotted #cccccc;
              "
            >
              <a-row class="d-flex">
                <a-col
                  :span="24"
                  style="margin: 0 10px; text-align: left"
                >
                  <div class="d-flex">
                    Catatan :
                  </div>
                </a-col>
              </a-row>
            </div>
            <div
              v-if="printDetailProduk"
              style="
                margin: 10px 0px;
                font-size: 12px;
                text-align: left;
                border-top: 1px solid #cccccc;
              "
            >
              <a-row class="d-flex" style="width: 100%">
                <a-col :span="20" style="margin: 10px 10px">
                  <div>Produk</div>
                </a-col>
                <a-col :span="2" style="margin: 10px 0">
                  <div>Jumlah</div>
                </a-col>
              </a-row>
              <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
                <a-col :span="20" style="margin: 1px 10px">
                  <div>{{ item.catalog_title }}</div>
                </a-col>
                <a-col :span="2" style="margin: 1px 0">
                  <div style="text-align: center">
                    <b>{{ item.quantity }}</b> {{ item.uom }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseInternal } from '@/api/warehouse'
// import { getStock } from '@/api/inventory'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import VueBarcode from '@chenfengyuan/vue-barcode'
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'FulfillmentLabel',
  components: {
    ArrowIcon,
    VueBarcode,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      tokopedia: require('@/assets/integration/channel/tokopedia.png'),
      sicepat: require('@/assets/courier/sicepat.png'),
      anteraja: require('@/assets/courier/anteraja.png'),
      jne: require('@/assets/courier/jne.png'),
      sap: require('@/assets/courier/sap.png'),
      paxel: require('@/assets/courier/paxel.png'),
      detail: [],
      product: [],
      destination: [],
      shipping: {},
      warehouseId: '',
      size: '',
      printDetailProduk: true,
      printFulfillmentId: true,
      shipment: {},
      order: {},
      sender: {},
    }
  },
  computed: {
    barcode() {
      return {
        height: 100,
        width: 1,
        displayValue: false,
      }
    },
    topBarcode() {
      return this.shipping.awb !== null ? this.shipping.awb : this.shipping.booking_no
    },
    isExist() {
      return this.detail[0] && this.detail[0].fulfillment_number
    },
  },
  mounted() {
    this.fetchDetailFulfillmentBasedOrderId()
  },
  methods: {
    parseISOString(dateTime) {
      if(dateTime) {
        const split = dateTime.split(/\D+/)
        const newDate = new Date(
          split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
        )
        return this.$moment(newDate).format('Do MMMM YYYY')
      } else { return '' }
    },
    getCourierLogo(param) {
      switch (param.toLowerCase()) {
        case 'sicepat' :
          return this.sicepat
        case 'anteraja' :
          return this.anteraja
        case 'jne' :
          return this.jne
        case 'sap' :
          return this.sap
        case 'paxel' :
          return this.paxel
      }
    },
    setDetailProduk(e) {
      this.printDetailProduk = e.target.checked
    },
    setPrintFulfillmentId(e) {
      this.printFulfillmentId = e.target.checked
    },
    setPageSize(value) {
      const style = document.createElement('style')
      style.innerHTML = `@page {size: ${value}}`
      document.head.appendChild(style)
    },
    async fetchDetailFulfillmentBasedOrderId() {
      await getFulfillmentDetail({
        url: 'fulfillment',
        internal: true,
        fulfillment_id: this.$route.query.fulfillment_id,
        params: { workspace_id: this.$route.query.workspace_id },
      })
      .then(({ data: { data: response } }) => {
        this.detail = response.fulfillments
        this.order = response.order
        this.getDataDetail()
        this.fetchWarehouseId(response.warehouse_id)
      })
      .catch((err) => console.error(err))
    },
    async fetchWarehouseId(warehouseId) {
      await getWarehouseInternal({
        business_id: this.$route.query.business_id,
        params: { warehouse_id: [warehouseId].join(',') },
      })
      .then(({ data: { data: response } }) => this.shipment = response.at(0))
      .catch((err) => console.error(err))
    },
    getDataDetail() {
      const label_id = this.$route.query.fulfillment_id
      const data = this.detail.find((item) => item.id === label_id)
      this.product = data.line_items
      this.destination = data.destination
      this.shipping = data.shipping
      this.sender = data.sender
    },
    back() {
      this.$router.push({
        name: this.$route.params.id === undefined ? 'fulfillment.index' : 'channel-fulfillment',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style  lang="scss">
// $pageSize: 148mm 105mm;

.label-shipment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  position: absolute;
  min-width: 599px;
  width: auto;
  height: auto;

  /* Global Color/White */

  background: #ffffff;
  /* Global Color/Grey/Grey-100 */

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  .label-shipment-image {
    min-width: 501px;
    max-width: 525px;
    width: auto;
    height: auto;
    box-sizing: border-box;
  }
  .label-shipment-content {
    min-width: 501px;
    max-width: 525px;
    width: auto;
    height: auto;
    border: 1px solid #000000;
    box-sizing: border-box;
  }
}
.box-print {
  position: absolute;
  width: 345px;
  height: auto;
  margin-left: 40px;

  /* Global Color/White */

  background: #ffffff;
  /* Global Color/Grey/Grey-100 */

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;

  .ant-radio-wrapper {
    color: #0559CB !important;
  }
}

.accept-button-modal {
  margin: 10px 0px;
  border: 1px solid #0559cb;
  background: #0559cb;
  color: #ffffff;
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
}
@media print {
  // @page {
  //   size: a4;
  // }
  body * {
    visibility: hidden;
  }
  .label-shipment * {
    visibility: visible;
  }
  .label-shipment {
    position: fixed;
    margin: 0;
    left: 0;
    top: 0;
  }
}
</style>